import {provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {Router} from '@angular/router';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import {CustomErrorHandlerService} from 'app/core/services/custom-error-handler.service';
import { provideLottieOptions } from 'ngx-lottie';

import { CoreModule } from 'src/app/core/core.module';
import { loadConfigProvider } from 'src/app/core/factories/initializer.factory';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';

@NgModule({
  bootstrap: [AppComponent], declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, DialogModule, CoreModule, NotificationModule,
    TooltipsModule, StoreModule.forRoot({}, {
      metaReducers: !environment.production ? [] : [], runtimeChecks: {
        strictActionImmutability: true, strictStateImmutability: true
      }
    }), EffectsModule.forRoot([]), !environment.production ? StoreDevtoolsModule.instrument() : []],
  providers: [{provide: ErrorHandler, useClass: CustomErrorHandlerService},
    {provide: Sentry.TraceService, deps: [Router]},
    {provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true},
    loadConfigProvider, provideLottieOptions({
      player: () => import('lottie-web')
    }), provideHttpClient(withInterceptorsFromDi())]
})
export class AppModule {}
