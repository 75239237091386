import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonGroupModule} from '@progress/kendo-angular-buttons';
import {UploadModule} from '@progress/kendo-angular-upload';
import {CustomHttpModule} from 'src/app/core/http/custom-http.module';
import {SharedModule} from 'src/app/shared/shared.module';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { ProfileDialogComponent } from './components/profile-dialog/profile-dialog.component';
import { DeleteUserDialogComponent } from './components/delete-user-dialog/delete-user-dialog.component';
import { BulkUploadDialogComponent } from './components/bulk-upload-dialog/bulk-upload-dialog.component';

@NgModule({
  declarations: [
    ChangePasswordDialogComponent,
    ProfileDialogComponent,
    DeleteUserDialogComponent,
    BulkUploadDialogComponent
  ],
  imports: [
    CommonModule,
    CustomHttpModule,
    SharedModule,
    ButtonGroupModule,
    UploadModule
  ]
})
export class CoreModule {}
