<div class="flex-container h-100" [style.gap]="gutter">
  <div class="animation">
    <ng-lottie [options]="optionsError"></ng-lottie>
    <div class="title">{{ title }}</div>
    <span class="loader-text">{{ text }}</span>
    <div class="k-d-flex k-flex-row k-gap-4 k-justify-content-center k-align-items-center">
    <div *ngIf="hasCancelButton" class="button-container">
      <button (click)="handleCancel()" [disabled]="isRetrying" themeColor="base" kendoButton>
        Cancel
      </button>
    </div>
    <button
      (click)="handleRetry()"
      [disabled]="isRetrying"
      [style.gap]="btnGutter"
      class="k-d-flex k-flex-row k-gap-2 k-align-items-center k-justify-content-center k-mt-4"
      themeColor="primary"
      kendoButton>
      <span *ngIf="!isRetrying">Retry</span>
      <span *ngIf="isRetrying" class="k-mr-2">Retrying</span>
      <kendo-loader
        *ngIf="isRetrying" type="pulsing" themeColor="light"
        size="small">
      </kendo-loader>
    </button>
  </div>
  </div>
</div>
