import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationService } from '@progress/kendo-angular-notification';
import {CustomErrorHandlerService} from 'app/core/services/custom-error-handler.service';

import { SignInResponse } from 'app/modules/portal/portal-container/models/signin-response.model';
import { UserProperties } from 'app/modules/portal/portal-container/models/user-properties.model';
import {
  INTEGRATION_ID_KEY,
  INTERNAL_CUSTOMER_ID_KEY, PRODUCT_NAME_KEY
} from 'app/modules/widget/config/local-storage.config';
import {HelperMethods} from 'app/modules/widget/utils/helper-methods';
import { ObjectMapper } from 'json-object-mapper';
import Mixpanel from 'mixpanel-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { cognito } from 'src/cognito.config';
import {environment} from 'src/environments/environment';

const authApiURL = `${cognito.selfserveportalLambdaRestApiEndpoint37915857}`;
const ERROR_MESSAGE = 'Something went wrong. Please try again later.';
const VERIFY_EMAIL_MESSAGE =
  'To continue, please check your email inbox and spam folder for a verification link.';
const TOKEN_KEY = 'token';
const CUSTOMER_ID_KEY = 'customerId';
const SIGNIN_SUCCESS = 'sign in - success';

@Injectable({ providedIn: 'root' })
export class PortalAuthService {
  private jwtHelper = new JwtHelperService();
  private userProperties = new UserProperties();
  /** Verify if the logged in user session is valid */
  public isLoggedIn$ = new Observable<boolean>();
  private readonly loginError = new BehaviorSubject<HttpErrorResponse>(null);
  private readonly loginLoading = new BehaviorSubject<boolean>(false);
  public loading$ = this.loginLoading
    .asObservable()
    .pipe(distinctUntilChanged());
  /** Whether or not there is any error thrown by cognito while logging in */
  public loginError$ = this.loginError
    .asObservable()
    .pipe(distinctUntilChanged());
  private readonly loginSessionError = new BehaviorSubject<string>(null);
  /** Whether or not there is any error while retrieving the session details. */
  public loginSessionError$ = this.loginSessionError
    .asObservable()
    .pipe(distinctUntilChanged());
  private readonly resetSubmitted = new BehaviorSubject<boolean>(false);
  /** represent if user submitted password reset request */
  public resetSubmitted$ = this.resetSubmitted
    .asObservable()
    .pipe(distinctUntilChanged());
  private readonly submitted = new BehaviorSubject<boolean>(false);
  public submitted$ = this.submitted
    .asObservable()
    .pipe(distinctUntilChanged());
  /** Token to be used for api calls obtained from user session */
  private readonly token = new BehaviorSubject(null);
  public token$ = this.token.asObservable().pipe(distinctUntilChanged());
  /** Represents the user session details containing the access token. */
  private readonly userSession = new BehaviorSubject<SignInResponse>(null);
  public userSession$ = this.userSession
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private _notification: NotificationService,
    private readonly _router: Router,
    private readonly _http: HttpClient, private readonly customErrorHandlerService: CustomErrorHandlerService,
    private route: ActivatedRoute
  ) {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      this.token.next(token);
      if (token) {
        this.setUserProperties(token);
      }
    } else {
      this.removeItemsFromLocalStorage();
    }
    this.isLoggedIn$ = this.token$.pipe(
      map((token) => !!token && !this.jwtHelper.isTokenExpired(token))
    );
  }

  private removeItemsFromLocalStorage(): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(CUSTOMER_ID_KEY);
    localStorage.removeItem(INTERNAL_CUSTOMER_ID_KEY);
    localStorage.removeItem(PRODUCT_NAME_KEY);
    localStorage.removeItem(INTEGRATION_ID_KEY);
  }

  public signUp(email: string, baseUrl: string, subscriptionId: string): void {
    this._http
      .post(`${authApiURL}signup`, { email, baseUrl, subscriptionId })
      .subscribe(
        () => {
          this.showNotification(VERIFY_EMAIL_MESSAGE, 'success');
          this.loginLoading.next(false);
        },
        (error) => {
          this.customErrorHandlerService.handleError(error);
          this.setError(error);
          this.showNotification(ERROR_MESSAGE, 'error');
          this.loginLoading.next(false);
        }
      );
  }

  public showNotification(
    content: string,
    type: 'success' | 'info' | 'error'
  ): void {
    this._notification.show({
      content: content,
      cssClass: 'button-notification',
      animation: { type: 'fade', duration: 400 },
      position: { horizontal: 'center', vertical: 'top' },
      type: { style: type, icon: true },
      closable: true,
    });
  }

  public signIn(email: string, token: string): void {
    const subscriptionId =
      this.route.snapshot.queryParamMap.get('subscriptionId');
    this._http.post(`${authApiURL}signin`, { email, token }).subscribe(
      (res: SignInResponse) => {
        const response = ObjectMapper.deserialize(SignInResponse, res);
        this.setSession(response);

        if (!JSON.parse(this.userProperties?.isAdmin)) {
          Mixpanel.init(environment.mixpanelToken, {
            debug: false
          });
          HelperMethods.initMixpanelForAuthFlow(this.userProperties.email, SIGNIN_SUCCESS);
        }

        // If subscriptionId is present, append it to the home page URL as a query parameter
        if (subscriptionId) {
          this._router.navigate(['/'], { queryParams: { subscriptionId } });
        } else {
          this._router.navigate(['/']);
        }
      },
      (error) => {
        this.loginLoading.next(false);
        this.setError(error);
        this._router.navigate(['/signup'], {
          queryParams: { subscriptionId },
        });
      }
    );
  }

  private setSession(session: SignInResponse): void {
    this.userSession.next(session);
    this.token.next(session?.idToken);

    localStorage.setItem(TOKEN_KEY, session?.idToken);

    this.setUserProperties(session?.idToken);

    const customerIds = this.userProperties.getCustomerIds();
    if (customerIds.length > 0) {
      localStorage.setItem(CUSTOMER_ID_KEY, customerIds[0]);
    }
  }

  private setUserProperties(token: string): void {
    try {
      this.userProperties = ObjectMapper.deserialize(
        UserProperties,
        this.jwtHelper.decodeToken(token)
      );
    } catch (error) {
      console.error('Error setting user properties', error);
    }
  }

  public getUserProperties(): UserProperties {
    return this.userProperties;
  }

  public isTokenExpired(): boolean {
    const token = this.token.getValue();
    if (!token) return true;
    //@ts-ignore
    return this.jwtHelper.isTokenExpired(token);
  }

  public signOut(state?: RouterStateSnapshot): void {
    const queryParams = state?.root?.queryParams;
    const subscriptionId = queryParams['subscriptionId'];
    this._router.navigate(['/signup'], {
      queryParams: { subscriptionId },
    });
    this.token.next(null);
    this.removeItemsFromLocalStorage();
  }

  private setError(error: HttpErrorResponse): void {
    this.submitted.next(false);
    this.loginError.next(error);
  }

  public setLoading(loading: boolean) {
    this.loginLoading.next(loading);
  }
}
