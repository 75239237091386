import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { RenewalFlowService } from 'app/modules/widget/services/renewal-flow.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { HelperMethods } from 'app/modules/widget/utils/helper-methods';

export interface BuUnit {
  name: string;
  internalName: string;
  logo: string;
  favicon: string;
  videoOverlayImage: string;
}

@Injectable({ providedIn: 'root' })
export class BUService {
  private buUnitSubject = new BehaviorSubject<BuUnit | null>(null);
  private buUnits = new BehaviorSubject<BuUnit[]>([]);
  public buUnits$: Observable<BuUnit[]> = this.buUnits.asObservable();
  public buUnit$: Observable<BuUnit | null> = this.buUnitSubject.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly renewalFlowService: RenewalFlowService
  ) {
    this.loadBUUnits();
  }

  private loadBUUnits(): void {
    this.renewalFlowService
      .getContentFromKontentIo('bu')
      .pipe(
        take(1),
        map((response) => this.mapBUUnits(response.items)),
        tap((buUnits) => this.buUnits.next(buUnits)),
        switchMap((buUnits) => this.detectBUUnit(buUnits).pipe(take(1)))
      )
      .subscribe((buUnit) => {
        this.buUnitSubject.next(buUnit);
      });
  }

  private detectBUUnit(buUnits: BuUnit[]): Observable<any> {
    let hostname = window.location.hostname;
    const params = new URLSearchParams(window.location.search);
    const testBUParam = params.get('bu');
    const testBU = testBUParam ? testBUParam.toLocaleLowerCase() : null;

    if ((hostname === 'localhost' || hostname.includes('pr-')) && testBU) {
      hostname = testBU;
    }

    let buUnit = buUnits.find((unit) => hostname.toLocaleLowerCase().includes(unit.internalName.toLocaleLowerCase()));

    if (!buUnit && hostname.includes('jigsawinteractive')) {
      buUnit = buUnits.find(unit => unit.internalName.toLocaleLowerCase() === 'jigsaw');
    }

    if (!buUnit) {
      buUnit = buUnits.find((unit) => unit.internalName.toLocaleLowerCase() === 'ignitetech');
    }

    // Set favicon
    this.setFavicon(buUnit.favicon);
    // Set document title
    this.setDocumentTitle(buUnit.name);
    return of(buUnit);
  }

  private mapBUUnits(items: any[]): BuUnit[] {
    return items.map((item) => ({
      name: item.elements.name.value,
      internalName: item.elements.internalname.value,
      logo: HelperMethods.formatAssetUrl(item.elements.logo.value),
      favicon: HelperMethods.formatAssetUrl(item.elements.favicon.value),
      videoOverlayImage: HelperMethods.formatAssetUrl(
        item.elements.videooverlayimage.value
      ),
    }));
  }

  private setFavicon(faviconUrl: string): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.rel = 'icon';
    link.href = faviconUrl;
    this.document.head.appendChild(link);
  }

  private setDocumentTitle(buName: string): void {
    this.document.title = `${buName !== 'JigTree' ? buName : 'Aurea'} | Account`;
  }

  public getBUUnit(): Observable<any> {
    return this.buUnit$;
  }
}
