import { cognito } from 'src/cognito.config';

export const environment = {
  production: true,
  apiUrl: 'https://api.si.trilogy.com/api/v1/',
  widgetUrl: `${location.origin}/widget?`,
  portalApiUrl: 'https://portal.si.trilogy.com/api/',
  kontentAPIUrl:
    'https://deliver.kontent.ai/2add83e7-0d69-00f1-aed7-efa1f489abfb/',
  s3BucketURL: 'https://self-serve-assets-prod.s3.amazonaws.com',
  voiceFlowUrl: 'https://general-runtime.voiceflow.com',
  voiceFlowApiKey: 'VF.DM.668db52c4739614b83262da9.IsCflTbC1UpHYbI3',
  voiceflowProjectId: '65fd8461d7a58aa63399e859', voiceflowVersionId: 'production',
  featheryScriptUrl:
    'https://cdn.jsdelivr.net/npm/@feathery/react@latest/umd/index.js',
  googleChatRoomKey: 'AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI',
  googleChatRoomToken: 'ujhdnY9SnAlNt4J03Arsbtsb1LtfLHfRRtrkhZiRn7M', googleChatSpace: 'AAAAX5QN9dM',
  sentryProjectId: '4507349708242944',
  sentryDsn: 'https://2f10779d7a5a91e530040fad886e5267@o4504227135291392.ingest.us.sentry.io/4507349708242944',
  ...cognito,
  mixpanelToken: '294ab6cc0abdcb1df089ca4918608631',
};
