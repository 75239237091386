import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule, ChipModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconModule } from '@progress/kendo-angular-icons';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { FormFieldModule, TextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { CardModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { MenusModule } from '@progress/kendo-angular-menu';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import {ProgressBarModule} from '@progress/kendo-angular-progressbar';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import {ProgressLoaderComponent} from 'app/shared/components/progress-loader/progress-loader.component';
import { CapitalizePipe } from 'app/shared/pipes/capitalize.pipe';
import { CustomCurrencyPipe } from 'app/shared/pipes/currency-format.pipe';
import { PhoneFormatPipe } from 'app/shared/pipes/phone-format.pipte';
import { PluralizePipe } from 'app/shared/pipes/plural.pipe';
import {LottieComponent} from 'ngx-lottie';
import { ErrorComponent } from 'src/app/shared/components/error/error.component';
import { DisableControlDirective } from 'src/app/shared/directives/disable-control.directive';

const SHARED_COMPONENTS = [ErrorComponent, ProgressLoaderComponent];

const SHARED_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  GridModule,
  NavigationModule,
  ButtonModule,
  IconModule,
  MenusModule,
  FormFieldModule,
  TextBoxModule,
  LabelModule,
  DropDownsModule,
  LoaderModule,
  DialogModule,
  TooltipsModule,
  CardModule,
  ListViewModule,
  ChipModule, ProgressBarModule
];

const SHARED_DIRECTIVES = [DisableControlDirective];
const SHARED_PIPES = [
  PhoneFormatPipe,
  PluralizePipe,
  CapitalizePipe,
  CustomCurrencyPipe,
];

/**
 * The shared module is used to hold all reusable components across the app's
 * different modules. It imports and exports reusable modules to make them
 * readily available to other feature modules just by importing the shared
 * module, avoiding repetition. Since the shared module is meant to be imported
 * by all feature-modules, it shouldn't provide any service.
 */
@NgModule({
  declarations: [...SHARED_DIRECTIVES, ...SHARED_COMPONENTS, ...SHARED_PIPES],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_DIRECTIVES,
    ...SHARED_COMPONENTS,
    ...SHARED_PIPES,], imports: [...SHARED_MODULES, LottieComponent]
})
export class SharedModule {}
