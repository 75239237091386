import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LottieConfig} from 'app/modules/widget/config/lottie.config';
import {AnimationOptions} from 'ngx-lottie';

const TEXT_GUTTER   = '8px';
const BUTTON_GUTTER = '4px';

@Component({
  selector:    'app-error',
  templateUrl: './error.component.html',
  styleUrls:   ['./error.component.scss']
})
export class ErrorComponent {
  /** Space to be used between text and spinner in button */
  public btnGutter           = BUTTON_GUTTER;
  /** Space to be used between error text and retry button */
  public gutter              = TEXT_GUTTER;

  public optionsError: AnimationOptions = LottieConfig.errorAnimation;
  /** Whether the retry button is clicked to retry the API call */
  @Input() public isRetrying = false;
  /** Whether the retry button is clicked to retry the API call */
  @Input() public hasCancelButton = false;
  /** Emits the event on clicking the retry button */
  @Output() public retry     = new EventEmitter<null>();
  /** Emits the event on clicking the retry button */
  @Output() public cancel    = new EventEmitter<null>();
  /** Represents the error text  */
  @Input() public text  = 'Oops! Something went wrong.';
  @Input() public title = 'This might be temporary, please try again.';

  /** handle retry button */
  public handleRetry(): void {
    this.retry.emit();
  }

  /** handle retry button */
  public handleCancel(): void {
    this.cancel.emit();
  }
}
