import { JsonProperty } from 'json-object-mapper';

export class PreviewResponse {
  @JsonProperty() public frequency: string;
  @JsonProperty() public totalAmount: number;
  @JsonProperty() public totalListPrice: number;
  @JsonProperty() public items: PricingItem[];
  @JsonProperty() public successPlanPremium: number;
  @JsonProperty() public onlyPlatinum: boolean;

  constructor() {
    this.frequency = null;
    this.totalAmount = 0;
    this.totalListPrice = 0;
    this.items = [];
    this.successPlanPremium = null;
    this.onlyPlatinum = null;
  }

  get isDiscountPresent(): boolean {
    return this.totalListPrice >= 0 && this.totalAmount < this.totalListPrice;
  }

  get annualRenewalPrice(): number {
    return this.totalAmount;
  }

  get discountAmount(): number {
    return this.totalListPrice > this.totalAmount
      ? this.totalListPrice - this.totalAmount
      : 0;
  }

  get getDiscountPercentage(): number {
    if (this.totalListPrice <= 0 && this.totalListPrice < this.totalAmount) {
      return 0;
    }

    const discount = (this.discountAmount / this.totalListPrice) * 100;
    return Math.round(discount);
  }
}

interface PricingItem {
  title: string;
  code: string;
  quantity: number;
  amount: number;
}
